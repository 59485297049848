
.my-navbar {
    background-color: #1E2022;
    height: 50px;
}

.my-navbar .navbar-brand {
    color: #EEEEEE;
    font-weight: bold;
    padding-left: 1%;
    font-size: 1.5rem;
    position: absolute;
}

.my-navbar .navbar-brand:hover,
.my-navbar .navbar-brand:focus {
    color: #00ADB5;
    font-weight: bold;
    font-size: 1.5rem;
}

.my-navbar .nav-link {
    color: #EEEEEE;
    font-size: 1.1rem;
}

.my-navbar .nav-link:hover,
.my-navbar .navbar-nav .nav-link.active {
    color: #00ADB5;
}

.my-navbar .navbar-collapse {
    background-color: #1E2022;
    padding-left: 2%;
}

.social-icons {
    padding: 0 10px !important;
    font-size: 24px !important;
  }
  
.social-icon {
    font-size: 24px;
    color: #EEEEEE;
}

.social-icon:hover {
    color: #00ADB5;
}
