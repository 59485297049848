@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;

}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    position: relative;
    background-color: #1B262C !important;
    color: #F0F5F9 !important;
    font-family: 'Lato', sans-serif !important;
}