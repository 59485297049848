#contact {
    padding-top: 15%;
    text-align: center;
    min-height: 100vh;
    overflow: auto;
    position: relative;
    background-color: #0F4C75;
    padding-left: 20%;
    padding-right: 20%;
}

#contact .form-control {
    width: 100%;
}