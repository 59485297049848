

#education {
    min-height: 100vh;
    overflow: auto;
    text-align: center;
    padding: 10%;
}

h2 {
    padding-bottom: 50px;
}

.education-img{
    width: 250px;
    height: 250px;
    padding-bottom: 2%
}