#experience-bar-container {
    width: 100%;
    margin: 1rem 0;
    padding-left: 2%;
    padding-right: 4%;
    text-align: left;
}

#experience-bar-container .skill{
    text-align: center;
}


.experience-bar {
    width: 100%;
    height: 25px;
    margin-top: 5px !important;
    background-color: #EEEEEE;
    border-color: #393E46;
    position: relative;
    z-index: -1;
}
  
.experience-bar-fill {
    height: 100%;
    background-color: #77037B;
    transition: width 1.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
}

.experience-bar span {
    float: right;
    padding-right: 0.5%;
    z-index: 1;
}

span {
    color: #1E2022;
}