#about {
    text-align: center;
    min-height: 100vh;
    padding-top: 75px;
    position: relative;
    overflow: auto;
    padding-right: 4%;
    padding-left: 4%;
}

.summary {
    line-height: 1.8rem;
}

.profile-image {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 400px;
    height: 400px;
    position: relative;
}