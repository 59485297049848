
#home {
    position: relative;
    overflow: auto;
    min-height: 100vh;
}

.intro {
    background-color: #0F4C75;
    border-bottom: solid 5px;
    border-bottom-color: #00ADB5;
    text-align: center;
    height: 100vh;
}

.intro-text {
    font-size: 32px;
    color: #EEEEEE;
}

.intro p {
    color: #EEEEEE;
}