#footer {
    text-align: center;
    justify-content: center;
    background-color: #0F4C75;
    padding: 1%;
}

#footer img {
    height: 75px;
    width: 250px;
    padding: 10px;
}
