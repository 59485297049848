#projects {
    min-height: 100vh;
    width: 100%;
    padding: 60px 0;
    overflow: auto;
    text-align: center;
    background-color: #0F4C75;
}

.projects-wrapper {
    margin: 0 auto;
}

#projects h2, #projects p, #projects h3, #projects h4 {
    color: #EEEE;
}

#projects p {
    font-size: 20px;
    line-height: 2rem;
}

.project-card {
    line-height: 1.8rem;
    border: solid 0.1px rgba(13, 20, 24, 0.075);
    border-radius: 5px;
    background-color: rgba(13, 20, 24, 0.075);
}

.project-card img {
    padding-bottom: 7%;
}

.project-skills {
    display: inline-block !important; 
    overflow: auto;
    white-space: nowrap;
}

.project-skill {
    font-size: 64px;
}


.demo-button {
    border-color: #222831 !important;
    background-color: #393E46 !important;
    color: #EEEEEE !important;
}

.demo-button:hover {
    border-color: #222831 !important;
    background-color: #272b30 !important;
    color: #EEEEEE !important;
}

.code-button {
    border-color: #222831 !important;
    background-color: #393E46 !important;
    color: #EEEEEE !important;
}

.code-button:hover {
    border-color: #222831 !important;
    background-color: #272b30 !important;
    color: #EEEEEE !important;
}